import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Axis, BarSeries, Chart, LineSeries, Position, ScaleType, Settings } from "@elastic/charts";
import { EUI_CHARTS_THEME_LIGHT } from "@elastic/eui/dist/eui_charts_theme";
import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiTitle } from '@elastic/eui';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import _ from "lodash";
const moment = extendMoment(Moment);
const PredictionsVsSeizures = ({ seizures, predictions, surveys, maxDate, minDate }) => {
    const highlightColor = '#fa4616';
    const subduedColor = '#D3DAE6';
    const lineColor = '#006BB4';
    if (!seizures.length && !predictions.length && !surveys.length) {
        return _jsx(EuiEmptyPrompt, { iconType: "dataVisualizer", iconColor: "default", title: _jsx("h2", { children: "Aucune donn\u00E9e disponible" }), titleSize: "xs", body: _jsx("p", { children: "Essayez d'\u00E9largir la plage de temps s\u00E9lectionn\u00E9e avec les calendriers ci-dessus." }) });
    }
    const data = [];
    const surveyData = [];
    const uniqueSeizuresByDay = Object.keys(_.groupBy(seizures, s => moment(s.datetime_seizure).startOf('day').format()));
    const timeRange = moment().range(minDate, maxDate);
    for (let week of timeRange.by('week')) {
        // create a composed key: 'year-week'
        const day = moment().day("Monday").year(week.year()).week(week.week()).format('YYYY-MM-DDT00:00:00');
        const nbPredictedSeizures = predictions.filter(p => moment(p.survey_date).isSame(week, 'week') && p.y_pred > 0).length;
        const nbSeizures = uniqueSeizuresByDay.filter(date => moment(date).isSame(week, 'week')).length;
        const nbSurveys = surveys.filter(p => moment(p.datetime_finish).isSame(week, 'week')).length;
        data.push({
            x: moment(day).valueOf(),
            y: nbPredictedSeizures,
            g: 'Crises prédictes',
        }, {
            x: moment(day).valueOf(),
            y: nbSeizures,
            g: 'Crises réelles',
        });
        surveyData.push({
            x: moment(day).valueOf(),
            y: nbSurveys,
        });
    }
    return (_jsxs("div", { children: [_jsxs(EuiFlexGroup, { children: [_jsx(EuiFlexItem, { grow: false, children: _jsx(EuiTitle, { size: "xxs", children: _jsxs("h4", { children: [_jsx(EuiIcon, { type: "dot", color: highlightColor }), " Pr\u00E9dictions de crises"] }) }) }), _jsx(EuiFlexItem, { grow: false, children: _jsx(EuiTitle, { size: "xxs", children: _jsxs("h4", { children: [_jsx(EuiIcon, { type: "dot", color: subduedColor }), " Crises r\u00E9elles"] }) }) }), _jsx(EuiFlexItem, { grow: false, children: _jsx(EuiTitle, { size: "xxs", children: _jsxs("h4", { children: [_jsx(EuiIcon, { type: "dot", color: lineColor }), " Nombre de questionnaires remplis"] }) }) })] }), _jsxs(Chart, { size: { height: '60vh' }, children: [_jsx(Settings, { theme: [
                            { colors: { vizColors: [subduedColor, highlightColor] } },
                            EUI_CHARTS_THEME_LIGHT.theme
                        ], showLegend: false, legendPosition: Position.Bottom, tooltip: {
                            headerFormatter: data => {
                                return "Semaine du " + moment(data.datum.x).format('DD/MM/YY');
                            }
                        } }), _jsx(BarSeries, { id: "bars", name: "0", data: data, xScaleType: ScaleType.Linear, yScaleType: ScaleType.Linear, xAccessor: 'x', yAccessors: ['y'], splitSeriesAccessors: ['g'], color: [highlightColor, subduedColor] }), _jsx(LineSeries, { id: "lines", name: "Questionnaires remplis", data: surveyData, xScaleType: ScaleType.Linear, yScaleType: ScaleType.Linear, xAccessor: "x", yAccessors: ['y'], color: lineColor }), _jsx(Axis, { id: "bottom-axis", position: "bottom", showGridLines: true, tickFormat: (d) => moment(d).format('DD/MM') }), _jsx(Axis, { id: "left-axis", position: "left", showGridLines: true })] })] }));
};
export default PredictionsVsSeizures;

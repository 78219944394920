import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPageTemplate, EuiPanel, EuiSpacer, EuiTitle } from "@elastic/eui";
import { Statistic } from "../common/Statistics";
import PredictionsVsSeizures from "./PredictionsVsSeizures";
import moment from "moment";
import { saveAs } from "file-saver";
const Dashboard = () => {
    const [seizures, setSeizures] = useState([]);
    const [predictions, setPredictions] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [activeUsers, setActiveUsers] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [precision, setPrecision] = useState(0);
    const [users, setUsers] = useState(0);
    const [startDate, setStartDate] = useState(moment().subtract(6, 'month'));
    const [endDate, setEndDate] = useState(moment());
    useEffect(() => {
        const fetchUrl = async (url, onResponse) => {
            const rawResponse = await fetch(url);
            const response = await rawResponse.json();
            onResponse(response);
        };
        const fetchData = async () => {
            const params = `?start=${startDate.format('YYYY-MM-DD')}&end=${endDate.add(1, 'day').format('YYYY-MM-DD')}`;
            const onSeizureResponse = async (r) => {
                setSeizures(prev => prev.concat(r.results || []));
                if (r.next) {
                    await fetchUrl(r.next, onSeizureResponse);
                }
            };
            await fetchUrl('/api/seizures/' + params, onSeizureResponse);
            const onPredictionResponse = async (r) => {
                setPredictions(prev => prev.concat(r.results || []));
                if (r.next) {
                    await fetchUrl(r.next, onPredictionResponse);
                }
            };
            await fetchUrl('/api/predictions/' + params, onPredictionResponse);
            const onSurveyResponse = async (r) => {
                setSurveys(prev => prev.concat(r.results || []));
                if (r.next) {
                    await fetchUrl(r.next, onSurveyResponse);
                }
            };
            await fetchUrl('/api/surveys/' + params, onSurveyResponse);
            await fetchUrl('/api/users/active_users_count/', res => setActiveUsers(res.count));
            await fetchUrl('/api/users/', res => setUsers(res.count));
            await fetchUrl('api/algorithm-train/evaluation_variables/', (res) => {
                setAccuracy((res === null || res === void 0 ? void 0 : res.accuracy) || 0);
                setPrecision((res === null || res === void 0 ? void 0 : res.precision) || 0);
            });
        };
        fetchData();
    }, [startDate, endDate]);
    const exportData = () => {
        saveAs("/api/export-survey-seizure/", "epiday_data.csv");
    };
    return (_jsx(EuiPageTemplate, { id: "dashboard", restrictWidth: false, template: "empty", pageHeader: {
            iconType: 'lensApp',
            iconProps: {
                color: '#fa4616'
            },
            bottomBorder: false,
            pageTitle: 'Tableau de bord Epiday',
            description: "Consultez les performances de l'algorithme de prédiction pour les crises d'épilepsie.",
            rightSideItems: [
                _jsx(Statistic, { color: 'success', description: "participants à l'étude", title: users }),
                _jsx(Statistic, { color: 'success', description: "utilisateurs actifs", title: activeUsers }),
                _jsx(Statistic, { color: 'success', description: "precision de l'algorithme", title: precision }),
                _jsx(Statistic, { color: 'success', description: "accuracy de l'algorithme", title: accuracy }),
            ]
        }, children: _jsx(EuiFlexGroup, { direction: "column", id: "patient-content", children: _jsx(EuiFlexItem, { children: _jsx(EuiFlexGroup, { children: _jsx(EuiFlexItem, { children: _jsxs(EuiPanel, { children: [_jsxs(EuiFlexGroup, { justifyContent: "spaceBetween", children: [_jsx(EuiFlexItem, { grow: false, children: _jsx(EuiTitle, { size: "s", children: _jsx("h2", { children: "Pr\u00E9dictions VS crises sur les six derniers mois" }) }) }), _jsx(EuiFlexItem, { grow: false, children: _jsx(EuiButton, { fill: true, onClick: exportData, children: "Exporter les donn\u00E9es en CSV" }) })] }), _jsx(EuiSpacer, { size: "l" }), _jsx(PredictionsVsSeizures, { seizures: seizures, predictions: predictions, surveys: surveys, minDate: startDate, maxDate: endDate })] }) }) }) }) }) }));
};
export default Dashboard;

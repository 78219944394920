import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';
import { Axis, BarSeries, BrushAxis, Chart, LineSeries, Position, ScaleType, Settings, } from '@elastic/charts';
import { EuiEmptyPrompt } from '@elastic/eui';
import _ from "lodash";
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
const SeizureVsMedicationChart = ({ seizures, treatments, minDate, maxDate, onMinChange, onMaxChange }) => {
    const theme = EUI_CHARTS_THEME_LIGHT.theme;
    if (!seizures.length && !treatments.length) {
        return _jsx(EuiEmptyPrompt, { iconType: "dataVisualizer", iconColor: "default", title: _jsx("h2", { children: "Aucune donn\u00E9e disponible" }), titleSize: "xs", body: _jsx("p", { children: "Essayez d'\u00E9largir la plage de temps s\u00E9lectionn\u00E9e avec les calendriers ci-dessus." }) });
    }
    const getDateFromWeek = (date) => {
        const [year, week] = date.split('-');
        return moment().day("Monday").year(year).week(week).format('YYYY-MM-DDT00:00:00');
    };
    const renameKeys = (obj) => Object.keys(obj).reduce((acc, key) => ({
        ...acc,
        ...{ [getDateFromWeek(key) || key]: obj[key] }
    }), {});
    const getEmptyWeeks = (minDate, maxDate) => {
        const timeRange = moment().range(minDate, maxDate);
        const emptyWeeks = {};
        for (let week of timeRange.by('week')) {
            // create a composed key: 'year-week'
            const yearWeek = `${moment(week).year()}-${moment(week).week()}`;
            emptyWeeks[yearWeek] = [];
        }
        return emptyWeeks;
    };
    const emptyWeeks = getEmptyWeeks(minDate, maxDate);
    const dataTreatments = [];
    treatments.forEach(t => {
        var _a;
        const startDate = moment(t.datetime_added) > minDate ? t.datetime_added : minDate;
        const endDate = moment(t.datetime_deleted) < maxDate ? t.datetime_deleted : maxDate;
        const timeRange = moment().range(startDate, endDate);
        for (let week of timeRange.by('week')) {
            // create a composed key: 'year-week'
            const day = moment().day("Monday").year(week.year()).week(week.week()).format('YYYY-MM-DDT00:00:00');
            const dosage = t.pill_data.dosage;
            // Check if we already have a treatment for the same week and medication (happens when the patient changes
            // the number of pills they take, for example)
            const alreadyExists = dataTreatments.find(x => x.x === moment(day).valueOf() && x.g === t.medication.dci_name);
            // Compute the daily dosage they take
            const newDosage = dosage * t.nb_morning + dosage * t.nb_noon + dosage * t.nb_evening;
            // If the treatment we already stored has the same dosage of this one, we want to override
            // the previous daily dosage with `newDosage`. Else, it means that the patient takes different pills
            // (10mg and 50mg, for example) so we will need to aggregate the daily dosage of the different pills
            const samePill = (_a = alreadyExists === null || alreadyExists === void 0 ? void 0 : alreadyExists.pillDosages) === null || _a === void 0 ? void 0 : _a.includes(dosage);
            if (alreadyExists) {
                // If the current treatment is the same pill but newer than the one we previously stored,
                // we remove the previous one to avoid duplicates
                if (moment(alreadyExists.added).isAfter(t.datetime_added) && !samePill) {
                    _.pull(dataTreatments, alreadyExists);
                }
            }
            dataTreatments.push({
                x: moment(day).valueOf(),
                y: (alreadyExists && !samePill) ? alreadyExists.y + newDosage : newDosage,
                g: t.medication.dci_name,
                pillDosages: (alreadyExists && !samePill) ? [...alreadyExists.pillDosages, dosage] : [dosage],
                added: moment(t.datetime_added).valueOf(),
            });
        }
    });
    const byMedication = _.groupBy(dataTreatments, 'g');
    const uniqueTreatments = Object.values(byMedication).map((values) => {
        return _.uniqBy(values, 'x');
    }).flat(1).sort((a, b) => a.x - b.x);
    const seizureGroups = seizures.reduce((acc, seizure) => {
        const dt = seizure.datetime_seizure;
        // create a composed key: 'year-week'
        const yearWeek = `${moment(dt).year()}-${moment(dt).week()}`;
        // add this key as a property to the result object
        if (!acc[yearWeek]) {
            acc[yearWeek] = [];
        }
        // push the current date that belongs to the year-week calculated before
        acc[yearWeek].push(seizure);
        return acc;
    }, emptyWeeks);
    const datesByWeek = renameKeys(seizureGroups);
    let data = [];
    Object.entries(datesByWeek).forEach(([key, seizs]) => {
        const small = seizs.filter(seiz => seiz.type_seizure === 1);
        const medium = seizs.filter(seiz => seiz.type_seizure === 2);
        const big = seizs.filter(seiz => seiz.type_seizure === 3);
        data.push({
            x: moment(key).valueOf(),
            y: small.length,
            g: "Petite crise"
        }, {
            x: moment(key).valueOf(),
            y: medium.length,
            g: "Moyenne crise"
        }, {
            x: moment(key).valueOf(),
            y: big.length,
            g: "Grosse crise"
        });
    });
    const sortedData = data.sort((a, b) => a.x - b.x);
    const customTheme = {
        ...theme,
    };
    const barSeriesColorAccessor = ({ specId, yAccessor, splitAccessors }) => {
        if (yAccessor === 'y') {
            const group = splitAccessors.get('g');
            if (group === "Grosse crise")
                return '#50406D';
            if (group === "Moyenne crise")
                return '#D3585A';
            return '#F7CC4C';
        }
        return null;
    };
    return (_jsx(Fragment, { children: _jsxs(Chart, { size: { height: 250 }, children: [_jsx(Settings, { theme: customTheme, showLegend: true, legendPosition: Position.Bottom, brushAxis: BrushAxis.X, onBrushEnd: ({ x: [start, end] }) => {
                        onMinChange(moment(Math.trunc(start)));
                        onMaxChange(moment(Math.trunc(end)));
                    }, tooltip: {
                        headerFormatter: data => {
                            return "Semaine du " + moment(data.datum.x).format('DD/MM/YY');
                        }
                    } }), _jsx(BarSeries, { id: "seizures", name: "Crises", data: sortedData, groupId: "group1", xScaleType: ScaleType.Linear, yScaleType: ScaleType.Linear, xAccessor: 'x', yAccessors: ['y'], splitSeriesAccessors: ['g'], stackAccessors: ['g'], color: barSeriesColorAccessor }), _jsx(LineSeries, { id: "lines", name: "Medication", data: uniqueTreatments, groupId: "group2", xScaleType: ScaleType.Linear, yScaleType: ScaleType.Linear, xAccessor: "x", yAccessors: ['y'], splitSeriesAccessors: ['g'], color: ['#DD0A73', '#017D73', '#F5A700', '#BD271E'] }), _jsx(Axis, { id: "bottom-axis", position: "bottom", showGridLines: true, showOverlappingTicks: true, tickFormat: (d) => moment(d).format('DD/MM') }), _jsx(Axis, { id: "left-axis", position: "left", groupId: "group1", showGridLines: true, tickFormat: (d) => Number(d).toFixed(0) }), _jsx(Axis, { id: "right", title: "Line axis", groupId: "group2", position: Position.Right, tickFormat: (d) => Number(d).toFixed(1) })] }) }));
};
export default SeizureVsMedicationChart;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiBadge, EuiCard, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import GalenicIcon from "./GalenicIcon";
const TIMES = {
    'morning': 'Matin',
    'noon': 'Midi',
    'evening': 'Soir'
};
const Medication = ({ medication }) => {
    var _a, _b, _c, _d;
    /**
     * This component displays the pills to take at a given time.
     * @param time - "morning", "noon" or "evening"
     */
    const PillByTime = ({ time }) => {
        const pillAtTime = (medication === null || medication === void 0 ? void 0 : medication.filter(med => med['nb_' + time] > 0)) || [];
        return pillAtTime.length > 0 ? (_jsxs(EuiFlexGroup, { style: { flexDirection: 'row', marginTop: 5 }, children: [_jsx(EuiFlexItem, { grow: false, children: TIMES[time] }), _jsx(EuiFlexItem, { children: pillAtTime.map((pill, i) => {
                        var _a, _b, _c, _d, _e;
                        return (_jsx("div", { children: _jsxs(EuiBadge, { style: { backgroundColor: (_a = pill.pill_data) === null || _a === void 0 ? void 0 : _a.color }, children: [pill['nb_' + time], ' ', _jsx(GalenicIcon, { color: (_b = pill.pill_data) === null || _b === void 0 ? void 0 : _b.color, name: (_c = pill.pill_data) === null || _c === void 0 ? void 0 : _c.galenic, size: null }), ' ', "de ", (_d = pill.pill_data) === null || _d === void 0 ? void 0 : _d.dosage, (_e = pill.pill_data) === null || _e === void 0 ? void 0 : _e.unit] }) }, time + pill.id));
                    }) })] })) : null;
    };
    return (_jsxs(EuiCard, { layout: "horizontal", display: "subdued", titleElement: "h6", title: (_b = (_a = medication[0]) === null || _a === void 0 ? void 0 : _a.medication) === null || _b === void 0 ? void 0 : _b.dci_name, description: (_d = (_c = medication[0]) === null || _c === void 0 ? void 0 : _c.medication) === null || _d === void 0 ? void 0 : _d.commercial_name, children: [_jsx(PillByTime, { time: 'morning' }), _jsx(PillByTime, { time: 'noon' }), _jsx(PillByTime, { time: 'evening' })] }));
};
export default Medication;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiIcon, EuiSpacer } from '@elastic/eui';
const EpilepsyDefinitions = ({ definitions, onChange }) => {
    const [epilepsy, setEpilepsy] = useState('');
    const [smallSeizure, setSmallSeizure] = useState('');
    const [mediumSeizure, setMediumSeizure] = useState('');
    const [bigSeizure, setBigSeizure] = useState('');
    useEffect(() => {
        setEpilepsy(definitions.epilepsy);
        setSmallSeizure(definitions.small_seizure);
        setMediumSeizure(definitions.medium_seizure);
        setBigSeizure(definitions.large_seizure);
    }, [definitions]);
    return (_jsxs(EuiForm, { component: "form", children: [_jsx(EuiFormRow, { label: "Epilepsie", fullWidth: true, children: _jsx(EuiFieldText, { fullWidth: true, placeholder: "Entrez la d\u00E9finition de l'\u00E9pilepsie", prepend: [
                        _jsx(EuiIcon, { type: 'dot', color: "#EFBAB5" }),
                    ], value: epilepsy, onChange: (e) => setEpilepsy(e.target.value), "aria-label": "Use aria labels when no actual label is in use" }) }), _jsx(EuiFormRow, { label: "Petite crise", fullWidth: true, children: _jsx(EuiFieldText, { fullWidth: true, placeholder: "Entrez la d\u00E9finition d'une petite crise", prepend: [
                        _jsx(EuiIcon, { type: 'dot', color: "#F7CC4C" }),
                    ], value: smallSeizure, onChange: (e) => setSmallSeizure(e.target.value), "aria-label": "Use aria labels when no actual label is in use" }) }), _jsx(EuiFormRow, { label: "Moyenne crise", fullWidth: true, children: _jsx(EuiFieldText, { fullWidth: true, placeholder: "Entrez la d\u00E9finition d'une crise moyenne", prepend: [
                        _jsx(EuiIcon, { type: 'dot', color: "#D3585A" }),
                    ], value: mediumSeizure, onChange: (e) => setMediumSeizure(e.target.value), "aria-label": "Use aria labels when no actual label is in use" }) }), _jsx(EuiFormRow, { label: "Grosse crise", fullWidth: true, children: _jsx(EuiFieldText, { fullWidth: true, placeholder: "Entrez la d\u00E9finition d'une grosse crise", prepend: [
                        _jsx(EuiIcon, { type: 'dot', color: "#50406D" }),
                    ], value: bigSeizure, onChange: (e) => setBigSeizure(e.target.value), "aria-label": "Use aria labels when no actual label is in use" }) }), _jsx(EuiSpacer, {}), _jsx("div", { style: { textAlign: "center" }, children: _jsx(EuiButton, { fill: true, onClick: () => onChange({
                        epilepsy,
                        small_seizure: smallSeizure,
                        medium_seizure: mediumSeizure,
                        large_seizure: bigSeizure,
                        id: definitions.id,
                    }), children: "Enregistrer les modifications" }) })] }));
};
export default EpilepsyDefinitions;

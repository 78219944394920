import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import _ from "lodash";
import { EuiFlexGrid, EuiFlexItem } from '@elastic/eui';
import Medication from "./Medication";
const Treatments = ({ patientId }) => {
    const [medications, setMedications] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const params = `?patient=${patientId}&active=true`;
            const rawTreatmentResponse = await fetch('/api/treatments/' + params);
            const treatmentResponse = await rawTreatmentResponse.json();
            setMedications((treatmentResponse === null || treatmentResponse === void 0 ? void 0 : treatmentResponse.results) || []);
        };
        fetchData();
    }, [patientId]);
    // We group the medications by ID, because the database has multiple entries
    // for the same medication (but with different dosages)
    const treatments = useMemo(() => {
        return _.groupBy(medications, 'medication.id');
    }, [medications]);
    return (_jsx(EuiFlexGrid, { children: Object.values(treatments).map((med, i) => _jsx(EuiFlexItem, { grow: false, children: _jsx(Medication, { medication: med }) }, 'treatment-' + i)) }));
};
export default Treatments;

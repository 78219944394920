import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { EuiAvatar, EuiButtonEmpty, EuiFlexGroup, EuiHeader, EuiHeaderLink, EuiHeaderLinks, EuiHeaderLogo, EuiHeaderSectionItem, EuiHeaderSectionItemButton, EuiPopover, htmlIdGenerator, } from '@elastic/eui';
import icon from "../../assets/brain.png";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
const HeaderUserMenu = () => {
    const headerUserPopoverId = htmlIdGenerator()();
    const [isOpen, setIsOpen] = useState(false);
    const onMenuButtonClick = () => {
        setIsOpen(!isOpen);
    };
    const closeMenu = () => {
        setIsOpen(false);
    };
    const button = (_jsx(EuiHeaderSectionItemButton, { "aria-controls": headerUserPopoverId, "aria-expanded": isOpen, "aria-haspopup": "true", "aria-label": "Account menu", onClick: onMenuButtonClick, children: _jsx(EuiAvatar, { name: 'admin', size: "s" }) }));
    return (_jsx(EuiPopover, { id: headerUserPopoverId, button: button, isOpen: isOpen, anchorPosition: "downRight", closePopover: closeMenu, panelPaddingSize: "none", children: _jsx(EuiFlexGroup, { gutterSize: "m", className: "euiHeaderProfile", responsive: false, children: _jsx(EuiButtonEmpty, { iconType: 'push', href: "/user/logout/", children: "D\u00E9connexion" }) }) }));
};
const Header = ({ location }) => {
    return (_jsxs(EuiHeader, { children: [_jsx(EuiHeaderSectionItem, { children: _jsx(EuiHeaderLogo, { className: 'logo', iconType: icon, children: _jsx("b", { children: "Epiday" }) }) }), _jsx(EuiHeaderSectionItem, { border: "none", children: _jsxs(EuiHeaderLinks, { "aria-label": "App navigation links example", children: [_jsx(Link, { to: "/", children: _jsx(EuiHeaderLink, { isActive: location.pathname === '/', children: "Tableau de bord" }) }), _jsx(Link, { to: "/patients", children: _jsx(EuiHeaderLink, { isActive: location.pathname.startsWith('/patients'), children: "Consulter une fiche patient" }) }), _jsx(EuiHeaderLink, { iconType: "popout", iconSide: "right", target: "_blank", href: "/admin/", children: "Administration" })] }) }), _jsx(EuiHeaderSectionItem, { children: _jsx(HeaderUserMenu, {}) })] }));
};
export default withRouter(Header);

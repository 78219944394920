import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EuiButton, EuiComboBox, EuiEmptyPrompt, EuiPageTemplate, EuiSpacer, EuiText } from '@elastic/eui';
import debounce from 'lodash.debounce';
const SelectPatient = () => {
    var _a;
    const [selectedOptions, setSelected] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const onChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };
    const onSearchChange = useCallback(async (searchValue) => {
        var _a;
        setLoading(true);
        setOptions([]);
        const rawResponse = await fetch('/api/users/?q=' + searchValue);
        const response = await rawResponse.json();
        setLoading(false);
        setOptions((_a = response.results) === null || _a === void 0 ? void 0 : _a.map(r => ({ label: r.username, value: r.id })));
    }, []);
    const debouncedChangeHandler = useMemo(() => debounce(onSearchChange, 800), []);
    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);
    useEffect(() => {
        // Simulate initial load.
        onSearchChange('');
    }, [onSearchChange]);
    const searchUrl = '/patients/' + (((_a = selectedOptions === null || selectedOptions === void 0 ? void 0 : selectedOptions[0]) === null || _a === void 0 ? void 0 : _a.value) || '');
    return (_jsx("div", { style: { backgroundColor: 'white' }, children: _jsx(EuiPageTemplate, { id: 'select-patient-page', template: "centeredBody", style: {
                height: '95vh',
                // backgroundImage: "url(" + background + ")",
                // backgroundPosition: 'top left',
                // backgroundRepeat: 'no-repeat'
            }, pageContentProps: { paddingSize: 'none' }, children: _jsx(EuiEmptyPrompt, { title: _jsx("b", { children: "Consulter une fiche patient" }), body: _jsxs("div", { children: [_jsx(EuiText, { size: "s", children: "Entrez l'identifiant de votre patient pour consulter sa fiche." }), _jsx(EuiSpacer, {}), _jsx(EuiComboBox, { placeholder: "Entrez un identifiant", async: true, options: options, selectedOptions: selectedOptions, isLoading: isLoading, onChange: onChange, singleSelection: { asPlainText: true }, onSearchChange: debouncedChangeHandler })] }), actions: [_jsx(EuiButton, { fill: true, disabled: !selectedOptions.length, href: searchUrl, children: "Chercher" })] }) }) }));
};
export default SelectPatient;

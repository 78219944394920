import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import * as d3 from "d3";
import moment from "moment";
import _ from "lodash";
const SeizureRadialChart = ({ seizures, timeRange }) => {
    const dimensions = {
        width: 500,
        height: 500,
        margin: { top: 25, right: 25, bottom: 25, left: 25 }
    };
    const svgRef = React.useRef(null);
    const { width, height, margin = {} } = dimensions;
    const svgWidth = width + margin.left + margin.right;
    const svgHeight = height + margin.top + margin.bottom;
    const innerRadius = 100;
    const outerRadius = Math.min(width, height) / 2;
    React.useEffect(() => {
        const seizuresByTime = _.groupBy(seizures, s => moment(s.datetime_seizure)[timeRange === 'hour' ? timeRange : 'date']());
        const nbSlots = timeRange === 'hour' ? 24 : 31;
        const slots = [...Array(nbSlots).keys()];
        const data = slots.map(time => {
            const seizuresAtTime = seizuresByTime[time] || [];
            const { small, medium, big } = seizuresAtTime.reduce((acc, val) => {
                if (val.type_seizure === 1)
                    return { ...acc, small: acc.small + 1 };
                if (val.type_seizure === 2)
                    return { ...acc, medium: acc.medium + 1 };
                if (val.type_seizure === 3)
                    return { ...acc, big: acc.big + 1 };
            }, { small: 0, medium: 0, big: 0 });
            return { x: time, smallSeizures: small, mediumSeizures: medium, bigSeizures: big, };
        });
        // Create root container where we will append all other chart elements
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove();
        const x = d3.scaleBand()
            .domain(data.map(d => d.x))
            .range([0, 2 * Math.PI])
            .align(0);
        const y = d3.scaleRadial()
            .domain([0, d3.max(data, d => d.smallSeizures + d.mediumSeizures + d.bigSeizures + 1)])
            .range([innerRadius, outerRadius]);
        const z = d3.scaleOrdinal()
            .domain(['smallSeizures', 'mediumSeizures', 'bigSeizures'])
            .range(["#F7CC4C", "#D3585A", "#50406D"]);
        const xAxis = g => g
            .attr("text-anchor", "middle")
            .call(g => g.selectAll("g")
            .data(data)
            .join("g")
            .attr("transform", d => `
          rotate(${((x(d.x) + x.bandwidth() / 2) * 180 / Math.PI - 90)})
          translate(${innerRadius},0)
        `)
            .call(g => g.append("line")
            .attr("x2", -5)
            .attr("stroke", "#000"))
            .call(g => g.append("text")
            .attr("transform", d => (x(d.x) + x.bandwidth() / 2 + Math.PI / 2) % (2 * Math.PI) < Math.PI
            ? "rotate(90)translate(0,16)"
            : "rotate(-90)translate(0,-9)")
            .text(d => d.x)));
        const yAxis = g => g
            .attr("text-anchor", "middle")
            .call(g => g.append("text")
            .attr("y", d => -y(y.ticks(3).pop()))
            .attr("dy", "-1em")
            .text("Nombre de crises"))
            .call(g => g.selectAll("g")
            .data(y.ticks(3).slice(1))
            .join("g")
            .attr("fill", "none")
            .call(g => g.append("circle")
            .attr("stroke", "#D3DAE6")
            .attr("stroke-opacity", 0.5)
            .attr("r", y))
            .call(g => g.append("text")
            .attr("y", d => -y(d))
            .attr("dy", "0.35em")
            .attr("stroke", "#fff")
            .attr("stroke-width", 5)
            .text(y.tickFormat(3, "s"))
            .clone(true)
            .attr("fill", "#000")
            .attr("stroke", "none")));
        const legend = g => g.append("g")
            .selectAll("g")
            .data(['smallSeizures', 'mediumSeizures', 'bigSeizures'])
            .join("g")
            .attr("transform", (d, i) => `translate(-40,${(i - (3 - 1) / 2) * 20})`)
            .call(g => g.append("rect")
            .attr("width", 18)
            .attr("height", 18)
            .attr("fill", z))
            .call(g => g.append("text")
            .attr("x", 24)
            .attr("y", 9)
            .attr("dy", "0.35em")
            .text(d => d.startsWith('big') ? "Grosse" : (d.startsWith('medium') ? "Moyenne" : "Petite")));
        const arc = d3.arc()
            .innerRadius(d => y(d[0]))
            .outerRadius(d => y(d[1]))
            .startAngle(d => x(d.data.x))
            .endAngle(d => x(d.data.x) + x.bandwidth())
            .padAngle(0.01)
            .padRadius(innerRadius);
        svg.attr("viewBox", `${-width / 2} ${-height / 2} ${width} ${height}`)
            .style("font", "10px sans-serif");
        svg.append("g")
            .selectAll("g")
            .data(d3.stack().keys(['smallSeizures', 'mediumSeizures', 'bigSeizures'])(data))
            .join("g")
            .attr("fill", d => z(d.key))
            .selectAll("path")
            .data(d => d)
            .join("path")
            .attr("d", arc);
        svg.append("g").call(xAxis);
        svg.append("g").call(yAxis);
        svg.append("g").call(legend);
    }, [seizures]);
    return _jsx("svg", { ref: svgRef, width: svgWidth, height: svgHeight });
};
export default SeizureRadialChart;

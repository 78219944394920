import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import * as d3 from "d3";
import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiTitle } from "@elastic/eui";
import { tip as d3tip } from "d3-v6-tip";
import moment from "moment";
const SurveyScatterPlot = ({ width, surveysWithSeizures }) => {
    const trueColor = '#017D73';
    const falseColor = '#BD271E';
    const dimensions = {
        height: 500,
        margin: { top: 25, right: 20, bottom: 35, left: 50 }
    };
    const svgRef = React.useRef(null);
    const { height, margin = {} } = dimensions;
    const svgWidth = width + margin.left + margin.right;
    const svgHeight = height + margin.top + margin.bottom;
    React.useEffect(() => {
        if ((surveysWithSeizures === null || surveysWithSeizures === void 0 ? void 0 : surveysWithSeizures.length) > 0) {
            const data = (surveysWithSeizures === null || surveysWithSeizures === void 0 ? void 0 : surveysWithSeizures.map(s => ({
                category: s.seizure ? "Vrai positif" : "Faux positif",
                predicted: s.prediction === 0 ? 'no_seizure' : 'seizure',
                x: new Date(s.date),
                y: s.prediction_probability,
            }))) || [];
            // Create root container where we will append all other chart elements
            const svg = d3.select(svgRef.current);
            svg.selectAll("*").remove();
            const x = d3.scaleUtc()
                .domain(d3.extent(data, d => d.x))
                .range([margin.left, width - margin.right]);
            const y = d3.scaleLinear()
                .domain([0, 1]).nice()
                .range([height - margin.bottom, margin.top]);
            const color = d3.scaleOrdinal(["Vrai positif", "Faux positif"], [trueColor, falseColor]);
            const shape = d3.scaleOrdinal(['seizure', 'no_seizure'], [d3.symbol().type(d3.symbolStar)(), d3.symbol().type(d3.symbolCircle)()]);
            const xAxis = g => g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickFormat(d3.timeFormat("%d/%m")))
                .call(g => g.select(".domain").remove())
                .call(g => g.append("text")
                .attr("x", width / 2)
                .attr("y", margin.bottom)
                .attr("fill", "currentColor")
                .attr("text-anchor", "end")
                .style('font-weight', 'bold')
                .text('Date'));
            const yAxis = g => g
                .attr("transform", `translate(${margin.left},0)`)
                .call(d3.axisLeft(y))
                .call(g => g.select(".domain").remove())
                .call(g => g.append("text")
                .attr("x", -height / 2)
                .attr("y", -40)
                .attr("fill", "currentColor")
                .attr('transform', `rotate(-90)`)
                .style('text-anchor', 'middle')
                .style('font-weight', 'bold')
                .text("Probabilité de faire une crise"));
            const grid = g => g
                .attr("stroke", "currentColor")
                .attr("stroke-opacity", 0.1)
                .call(g => g.append("g")
                .selectAll("line")
                .data(x.ticks())
                .join("line")
                .attr("x1", d => 0.5 + x(d))
                .attr("x2", d => 0.5 + x(d))
                .attr("y1", margin.top)
                .attr("y2", height - margin.bottom))
                .call(g => g.append("g")
                .selectAll("line")
                .data(y.ticks())
                .join("line")
                .attr("y1", d => 0.5 + y(d))
                .attr("y2", d => 0.5 + y(d))
                .attr("x1", margin.left)
                .attr("x2", width - margin.right));
            const tip = d3tip()
                .attr('class', 'd3-tip')
                .html((event, d) => {
                const date = moment(d.x).format('DD/MM/YY');
                const prediction = d.predicted === 'seizure' ? 'Prédiction de crise' : "Prédiction d'absence de crise";
                return `Le ${date} : \n\n ${prediction} avec probabilité de ${d.y.toFixed(2)} `;
            });
            svg.call(tip);
            svg.append("g")
                .call(xAxis);
            svg.append("g")
                .call(yAxis);
            svg.append("g")
                .call(grid);
            svg.append("g")
                .attr("stroke-width", 1.5)
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .selectAll("path")
                .data(data)
                .join("path")
                .attr("transform", d => `translate(${x(d.x)},${y(d.y)})`)
                .attr("fill", d => color(d.category))
                .attr("d", d => shape(d.predicted));
            svg.selectAll('path')
                .on('mouseover', tip.show)
                .on('mouseout', tip.hide)
                .on('mouseleave', tip.hide);
        }
    }, [surveysWithSeizures]);
    return (surveysWithSeizures === null || surveysWithSeizures === void 0 ? void 0 : surveysWithSeizures.length) > 0 ? _jsxs("div", { children: [_jsxs(EuiFlexGroup, { children: [_jsx(EuiFlexItem, { grow: false, children: _jsx(EuiTitle, { size: "xxs", children: _jsxs("h4", { children: [_jsx(EuiIcon, { type: "starFilled", color: trueColor }), " Vrai positif"] }) }) }), _jsx(EuiFlexItem, { grow: false, children: _jsx(EuiTitle, { size: "xxs", children: _jsxs("h4", { children: [_jsx(EuiIcon, { type: "starFilled", color: falseColor }), " Faux positif"] }) }) }), _jsx(EuiFlexItem, { grow: false, children: _jsx(EuiTitle, { size: "xxs", children: _jsxs("h4", { children: [_jsx(EuiIcon, { type: "dot", color: trueColor }), " Vrai n\u00E9gatif"] }) }) }), _jsx(EuiFlexItem, { grow: false, children: _jsx(EuiTitle, { size: "xxs", children: _jsxs("h4", { children: [_jsx(EuiIcon, { type: "dot", color: falseColor }), " Faux n\u00E9gatif"] }) }) })] }), _jsx("svg", { ref: svgRef, width: svgWidth, height: svgHeight })] }) : (_jsx(EuiEmptyPrompt, { iconType: "dataVisualizer", iconColor: "default", title: _jsx("h2", { children: "Aucune donn\u00E9e disponible" }), titleSize: "xs", body: _jsx("p", { children: "Essayez d'\u00E9largir la plage de temps s\u00E9lectionn\u00E9e avec les calendriers ci-dessus." }) }));
};
export default SurveyScatterPlot;
